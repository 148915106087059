<template>
  <div>
    <Navbar v-if="project" />
    <ContactModal v-if="project" />
    <MobymeetModal v-if="project" :projectId="project.project_id" />
    <Header
      v-if="project"
      :projectHeader="true"
      :showImage="true"
      :showMobileImage="true"
      :projectNameImage="project.featured_project_third_image_url"
      :projectImages="project.project_images"
      :title="project.project_detail.header_title"
      :italicTitle="project.project_detail.header_italic_title"
      :projectCommune="project.commune"
      :projectNeighborhood="project.neighborhood"
      :subtitle="project.project_detail.header_description"
      :image="project.project_detail.header_image_url"
      :imageMobile="project.project_detail.header_image_url"
      :text_color="project.project_detail.header_text_color"
      :showBlackSeal="project.black_seal"
      :showNewProjectSeal="project.new_project"
    />
    <Menu
      v-if="project"
      :masterplan="
        project.project_detail.detail_masterplan != null ? true : false
      "
      :enviroments="
        project.project_detail.enviroments.length > 0 ? true : false
      "
      :tour360="project.view_360 ? true : false"
      :projectId="project.project_id"
      :state="project.actual_state"
      @goTo="goToAction"
      :digitalBrochureUrl="project.digital_brochure_url"
    />
    <TypesFilter
      v-if="project && project.project_id == 8"
      @changeType="changeTypeAction"
    />
    <MainFeatures
      ref="mainFeatures"
      v-if="project"
      :changeInfo="project.project_id === 8 ? true : false"
      :actualType="this.propertiesType"
      :hr="project.project_id === 8 ? true : false"
      :greyBackground="project.project_id === 8 ? true : false"
      :typesFilter="project.project_id == 8 ? true : false"
      :description="project.project_detail.main_features_description"
      :maxMt2="project.max_mt2"
      :mainFeatures="project.project_detail.detail_main_features"
      :features="project.project_detail.detail_features"
      :terminations="project.project_detail.detail_terminations"
      :equipments="project.project_detail.detail_equipments"
      :detailHouseDescription="
        project.project_detail.detail_house
          ? project.project_detail.detail_house.description
          : null
      "
      :houseImage="
        project.project_detail.detail_house
          ? project.project_detail.detail_house.image_url
          : null
      "
      :detailHouseMainFeatures="
        project.project_detail.detail_house
          ? project.project_detail.detail_house.detail_house_main_features
          : null
      "
      :detailSiteDescription="
        project.project_detail.detail_house
          ? project.project_detail.detail_site.description
          : null
      "
      :siteImage="
        project.project_detail.detail_house
          ? project.project_detail.detail_site.image_url
          : null
      "
      :detailSiteMainFeatures="
        project.project_detail.detail_house
          ? project.project_detail.detail_site.detail_site_main_features
          : null
      "
    />
    <EnviromentsNew
      ref="enviroment"
      v-if="project"
      :pilots="project.project_detail.enviroments"
    />
    <Quote
      ref="quotes"
      v-if="
        project &&
        propertiesProjectId != 11 &&
        project.actual_state != 'Realizado'
      "
      :project="vistaGolf ? vistaGolf : project"
      :propertiesType="propertiesType"
      :propertiesProjectId="propertiesProjectId"
    />
    <QuoteVistaGolf
      ref="quotes"
      v-if="
        project &&
        propertiesProjectId == 11 &&
        project.actual_state != 'Realizado'
      "
      :project="vistaGolf ? vistaGolf : project"
      :propertiesType="propertiesType"
      :propertiesProjectId="propertiesProjectId"
    />
    <Pilots
      ref="pilots"
      v-if="project"
      :pilots="project.project_detail.pilots"
      :description="project.project_detail.pilots_description"
    />
    <Tour360 ref="tour360" :tour360="project.view_360" />
    <Masterplan
      ref="masterplan"
      v-if="project && project.project_detail.detail_masterplan"
      :description="project.project_detail.detail_masterplan.description"
      :image="project.project_detail.detail_masterplan.image_url"
      :iframe="project.project_detail.detail_masterplan.iframe"
    />
    <LifeQuality
      v-if="project && project.project_detail.life_quality"
      :description="project.project_detail.life_quality.description"
      :images="project.project_detail.life_quality.life_quality_images"
      :attributes="project.project_detail.life_quality.life_quality_attributes"
    />
    <BigMoments
      v-if="project && project.project_detail.big_moment"
      :title="project.project_detail.big_moment.title"
      :title_italic="project.project_detail.big_moment.title_italic"
      :subtitle="project.project_detail.big_moment.subtitle"
      :items="project.project_detail.big_moment.big_moments_infos"
    />
    <!-- <Enviroments
      v-if="project && project.project_detail.enviroments.length > 0"
      :enviroments="project.project_detail.enviroments"
    /> -->
    <!-- <Features ref="features" v-if="project" :features="project.project_detail.detail_features"/> -->
    <SalesRoom
      ref="salesRoom"
      v-if="
        project &&
        project.actual_state != 'Realizado' &&
        project.project_detail.sales_room
      "
      :data="project.project_detail.sales_room"
    />
    <!-- <PayYourPromise ref="payYourPromise" v-if="project && project.actual_state != 'Realizado'" :promiseImages="project.project_detail.promise_images"/> -->
    <Footer v-if="project" />
  </div>
</template>

<script>
import Navbar from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/ProjectHeader.vue";
import Menu from "@/components/Details/Menu.vue";
import MainFeatures from "@/components/Details/MainFeatures.vue";
import Quote from "@/components/Details/Quote.vue";
import QuoteVistaGolf from "@/components/Details/QuoteVistaGolf.vue";
import Pilots from "@/components/Details/Pilots.vue";
import Tour360 from "@/components/Details/Tour360.vue";
import Masterplan from "@/components/Details/Masterplan.vue";
import LifeQuality from "@/components/Details/LifeQuality.vue";
import BigMoments from "@/components/Details/BigMoments.vue";
import EnviromentsNew from "@/components/Details/EnviromentsNew.vue";
// import Enviroments from "@/components/Details/Enviroments.vue";
// import Features from '@/components/Details/Features.vue'
import SalesRoom from "@/components/Details/SalesRoom.vue";
// import PayYourPromise from '@/components/Details/PayYourPromise.vue'
import ContactModal from "@/components/ContactModal.vue";
import TypesFilter from "@/components/Details/TypesFilter.vue";
import MobymeetModal from "@/components/MobymeetModal.vue";
export default {
  name: "ProjectDetail",
  components: {
    Navbar,
    Header,
    Menu,
    MainFeatures,
    Quote,
    QuoteVistaGolf,
    Pilots,
    Tour360,
    Masterplan,
    LifeQuality,
    BigMoments,
    EnviromentsNew,
    // Enviroments,
    // Features,
    SalesRoom,
    // PayYourPromise,
    ContactModal,
    Footer,
    TypesFilter,
    MobymeetModal,
  },
  props: {
    project_id: { default: null },
  },
  data() {
    return {
      propertiesType: "apartments",
      propertiesProjectId: null,
      vistaGolf: null,
    };
  },
  methods: {
    goToAction(destiny) {
      var element = this.$refs[destiny].$refs[destiny];
      var headerOffset = 25;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    },
    changeTypeAction(type, project_id) {
      this.propertiesType = type;
      this.propertiesProjectId = project_id;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    project() {
      return this.$store.getters.getProjectByLink(this.project_id);
    },
    projects() {
      return this.$store.getters.getProjects;
    },
  },
  watch: {
    propertiesProjectId(newValue) {
      if (newValue) {
        console.log(this.propertiesProjectId);
        this.vistaGolf = this.$store.getters.getProjectByLink(
          this.propertiesProjectId
        );
        console.log(this.vistaGolf);
      }
    },
  },
};
</script>

<style>
/* sticky button */
#feedback {
  color: white;
  height: 0px;
  width: 85px;
  position: fixed;
  right: 0;
  top: 40%;
  z-index: 1000;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  font-family: "Montserrat" !important;
}
#feedback a {
  display: block;
  background: #6ebf58;
  font-family: "Montserrat" !important;
  text-transform: uppercase;
  height: 52px;
  padding-top: 10px;
  width: 195px;
  text-align: center;
  color: #fff;
  font-family: Arial, sans-serif;
  font-size: 17px;
  font-weight: bold;
  text-decoration: none;
}
#feedback a:hover {
  background: #4b813b;
}
#mobymeetButton {
  color: white;
  height: 0px;
  width: 85px;
  position: fixed;
  right: 0;
  top: 75%;
  z-index: 1000;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  font-family: "Montserrat" !important;
}
#mobymeetButton a {
  display: block;
  background: #434041;
  font-family: "Montserrat" !important;
  text-transform: uppercase;
  height: 52px;
  padding-top: 10px;
  width: 215px;
  text-align: center;
  color: #fff;
  font-family: Arial, sans-serif;
  font-size: 17px;
  font-weight: bold;
  text-decoration: none;
}
#mobymeetButton a:hover {
  background: #2c2a2b;
}
</style>
